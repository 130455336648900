<template>
    <div class="login container">
        <div class="login-inner" id="register-page">
            <form :class="{ 'd-none': !showInputs }" class="login-form" method="post"  action="javascript:void(0);" @submit="createAccount">
                <div class="page-top-nav mb-3" v-if="config.phoneSupported && config.emailSupported">
                    <li :class="{ active: !emailVCode }">
                        <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
                    </li>
                    <li :class="{ active: emailVCode }">
                        <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
                    </li>
                </div>

                <div class="form-group">
                    <template v-if="emailVCode">
                        <!-- Email account -->
                        <label class="form-label" for="Name">{{ $t('register.label_email') }}</label>
                        <!-- Required by the interface -->
                        <!-- <input type="hidden" name="RegionCode" value="+1" /> -->
                        <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
                    </template>
                     <template v-else>
                        <!-- Phone account -->
                        <label class="form-label" for="Name">{{ $t('register.label_phone') }}</label>
                        <div class="d-flex">
                            <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                            <div class="flex-fill">
                                <input
                                    v-model.trim="account"
                                    name="Name"
                                    type="text"
                                    autocomplete="off"
                                    pattern="\d*"
                                    data-val="true"
                                    data-val-required="*"
                                    data-val-regex="*"
                                    data-val-regex-pattern="^0?[1-9]\d+$"
                                    class="form-control round-right"
                                    :placeholder="$t('user.label_account')"
                                />
                            </div>
                        </div>
                    </template>
                </div>
                <div class="form-group">
                    <div class="password-box">
                        <label>{{ $t('register.label_password') }}</label>
                        <input
                            name="Password"
                            v-model.trim="password"
                            :type="showPassword?'text':'password'"
                            autocomplete="new-password"
                            class="form-control"
                            data-val="true"
                            data-val-required="*"
                            data-val-maxlength="*"
                            data-val-maxlength-max="32"
                            data-val-minlength="*"
                            data-val-minlength-min="2"
                            :placeholder="$t('register.label_password')"
                        />
                        <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                        </svg>
                        <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                        </svg>
                    </div>
                </div>
                <div class="form-group">
                    <label>{{ $t('register.label_confirm_password') }}</label>
                    <div class="password-box">
                        <input
                            name="ConfirmPassword"
                            v-model.trim="confirmPassword"
                            :type="showConfirmPassword?'text':'password'"
                            autocomplete="new-password"
                            class="form-control"
                            data-val="true"
                            data-val-required="*"
                            data-val-equalto="*"
                            data-val-equalto-other="Password"
                            data-val-maxlength="*"
                            data-val-maxlength-max="32"
                            data-val-minlength="*"
                            data-val-minlength-min="6"
                            :placeholder="$t('register.label_confirm_password')"
                        />
                        <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                        </svg>
                        <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                        </svg>
                    </div>
                </div>
                <div class="form-group" v-if="emailVCode">
                    <label class="form-label" for="Phone">{{ $t('register.bind_phone') }}</label>
                    <div class="d-flex">
                        <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                        <div class="flex-fill">
                            <input v-model.trim="Phone" name="Phone" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                        </div>
                    </div>                
                </div>
                <div class="form-group">
                    <label>{{ $t('register.label_referral_code') }}</label>
                    <div class="code">
                        <input
                            type="text"
                            name="ReferralCode"
                            v-model.trim="refCode"
                            autocomplete="off"
                            class="form-control"
                            data-val="true"
                            data-val-required="*"
                            :placeholder="$t('register.label_referral_code')"
                        />
                    </div>
                </div>

                <!-- Do we need SMS verification? or just use a simple image verification code? -->
                <sms-verification v-if="config.signupVCodeRequired" :regionCode="regionCode" :emailVCode="emailVCode" :account="account" action="signup" />
                <template v-else>
                    <div class="form-group">{{ $t('register.label_verification_desp') }}: {{ regionCode }} {{ account }}</div>
                    <!-- <vcode-image /> -->

                    <!-- Use a dummy sms code to make the API happy. -->
                    <input type="hidden" name="Code" value="000000" />
                </template>

                <!-- <button class="btn btn-submit" type="submit">{{ $t('register.label_next_step') }}</button> -->
                <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
                    {{ $t('register.label_register') }}
                </button>
                <div class="text-center form-group">
                    <router-link class="login-link" to="/user/login">{{ $t('register.label_login') }}</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<style scoped>
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
.form-group .form-control:focus {
    outline: none;
    border-color: #8C89EB;
}
.login-link {
    font-size: 12px;
    color: #7F8490;
}
@media (max-width: 768px) {
    #register-page {
        margin: 0;
        padding: 0;
    }
    .login-page .form-group {
        margin-bottom: 10px;
    }
    
    .login-page .form-group label {
        display: block;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        line-height: 45px;
    }
    .login-page .form-control {
        border: none;
        border-radius: 10px;
        background-color: #FAFAFE;
    }
    #login-page button.btn-submit {
        border-radius: 10px;
        font-size: 16px;
        font-weight: 600;
    }
    .page-top-nav {
        margin-bottom: 1rem !important;
    }
    .login-page .form-group label {
        display: block;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #000000;
        line-height: 45px;
    }
}
</style>
<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';

export default {
    components: { RegionSelector },
    props: ['config', 'regions', 'referral'],

    data() {
        return {
            regionCode: null,
            account: null,
            password: null,
            Phone:null,
            confirmPassword: null,
            refCode: null,
            showInputs: true,

            // Submit status
            submitting: false,
            completed: false,
            emailVCode:false,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    created() {
        const config = this.config;
        if (!config || config.signupEnabled !== true) throw new Error('signupEnabled must be true when creating new accounts.');

        const regions = this.regions;
        if (!Array.isArray(regions)) throw new Error('An array of supported regions are required.');

        this.refCode = this.referral;
    },

    watch: {
        referral: function () {
            this.refCode = this.referral;
        },
        emailVCode: function () {
            this.resetAll();
            Vue.nextTick(() => {
                $.resetValidators();
            });

        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        resetAll: function() {
           this.account=null;
           this.password=null;
           this.confirmPassword=null;
           this.refCode=null;
           this.Phone=null;
        },
        // goNextPage: function (e) {
        //     const frm = $(e.target);
        //     if (frm.valid()) {
        //         this.showInputs = false;
        //     }
        //     return false;
        // },

        // goPrevPage: function () {
        //     this.showInputs = true;
        // },

        createAccount: function (e) {
            const frm = $(e.target);
            if (this.password !== this.confirmPassword) {
                $.top_error(this.$t("withdraw_password.err_different_passwords"));
                return;
            }
            if (!frm.valid()) return false;

            // post the server
            const data = frm.serialize();
            const self = this;
            self.submitting = true;

            $.callPostApi(self, '/api/v1/account/create', data)
                .then((json) => {
                    if (json && json.errcode === 0) {
                        // Save the current authenticated user.
                        setProfile(json.data);

                        // redirect to home page
                        self.completed = true;

                        setTimeout(function () {
                            if (self.config.requiresWithdrawPassword === true) {
                                self.$router.push('/user/changewithdrawpwd?skip=true');
                            } else {
                                self.$router.push('/user');
                            }
                        }, 500);
                    }else if(json.errcode === 103){
                        // 什么也不做，保持loading状态
                    }else {
                        self.submitting = false;
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    self.submitting = false;
                    $.top_error(self.$t('general.operation_error'));
                })
        },

        onRegionChanged: function (val) {
            console.log(`## Region changed to ${val}`);
            this.regionCode = val;
        }
    }
};
</script>