<template>
  <section class="page home-page">
    <section class="pro_layout">
      <loading-indicator v-if="!symbols" />
      <div v-else>
        <div class="home-top">
          <top-banners
            v-if="context.homeConfig"
            :home_config="context.homeConfig"
          />
        </div>
        <bottom-nav class="home-nav" :active_tab="active_tab" />
        <section class="content-first container">
          <div class="yellow">
            <div class="title">{{$t('home.home_design')}}</div>
            <div class="text">{{$t('intro.security_desp')}}</div>
          </div>
          <div class="red">
            <div class="title">
              <div class="right"> {{$t('home.home_design')}}</div>
              <div class="left">
                <img src="@/assets/images/EA/progress.png" />
              </div>
            </div>
            <div class="text">{{$t('intro.security_desp')}}</div>
          </div>
          <div class="blue">
            <div class="title">{{$t('home.home_design')}}</div>
            <div class="text">{{$t('intro.security_desp')}}</div>
          </div>
        </section>
        <div class="page-part" v-if="context.homeConfig && context.homeConfig.promptedNotices && context.homeConfig.promptedNotices.length">
            <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
        </div>
        <inline-svg-icons :symbols="symbols" />
        <section class="chosen container">
           <div class="title">{{$t("home.h1_desp")}}</div>
           <div class="content">
              <div class="first">
                <div class="one"></div><div  class="two">{{$t("home.market_trend")}}</div><div  class="three">{{$t("home.market_trend")}}</div><div  class="forth"></div>
              </div>
              <div class="second">
                <div class="one">{{$t("home.market_trend")}}</div><div  class="two">{{$t("home.market_trend")}}</div><div  class="three">{{$t("home.market_trend")}}</div>
              </div>
              <div class="third">
                <div class="one"></div><div  class="two">{{$t("home.market_trend")}}</div><div  class="three">{{$t("home.market_trend")}}</div><div  class="forth"></div>
              </div>
           </div>
        </section> 
        <div class="container ensures">
          <div class="title">{{ $t('home.market_trend') }}</div>
          <div class="content"></div>
         </div>
        <div class="quotes container">
          <div class="col">
              <h2 class="table-title">
                  {{ $t('home.risk_notice_line1') }}
              </h2>
              <div class="main-block-wrapper">
                  <home-quotes ref="quotes" :symbols="symbols" />
              </div>
          </div>
        </div>
        <div class="promblem container">
          <img src="@/assets/images/EA/qamark.png" class="left" /> 
          <img src="@/assets/images/EA/mark.png" class="right" />
          <div class="table-title">
                {{ $t('home.market_trend') }}
          </div>
          <div class="lggrren content">
            <div class="one">{{ $t('home.market_trend') }}</div><div class="two">{{ $t('home.market_trend') }}</div><div class="three">{{ $t('home.market_trend') }}</div><div class="four">{{ $t('home.market_trend') }}</div>
          </div>
          <div class="lmgrren content">
            <div> <div class="one">{{ $t('home.market_trend') }}</div><div class="two">{{ $t('home.market_trend') }}</div></div>
            <div><div class="three">{{ $t('home.market_trend') }}</div><div class="four">{{ $t('home.market_trend') }}</div></div>    
          </div>
          <div class="check"><div>{{$t("home.home_check")}}</div></div>
        </div>
        <div class="container beauty">
          <div class="left">
            <h4>{{ $t('home.market_trend') }}</h4>
            <div class="text">{{ $t('home.footer_slogen') }}</div>
            <!-- <div class="imgs">
              <img src="@/assets/images/EA/coins_one.png" />
              <img src="@/assets/images/EA/coins_two.png" />
              <img src="@/assets/images/EA/coins_three.png" />
              <img src="@/assets/images/EA/coins_four.png" />
            </div> -->
            <h4>{{ $t('home.market_trend') }}</h4>
            <div class="text">{{ $t('home.download_desp') }}</div>
            <div class="trde" @click="goToTradePage()">{{ $t("general.trade")}}</div>
            <a class="hleps" href="javascript:;" onclick="toggle_udesk()">{{ $t("helps.title")}}</a>
          </div>
          <div class="right"><div class="chilren"><img src="@/assets/images/EA/beauty@2x.png" class="img" /></div></div>
          <div class="foot-hleps">{{ $t("helps.title")}}</div>
        </div>
        <partner-links
          v-if="
            context && context.homeConfig && context.homeConfig.spl === true
          "
        />
      </div>
    </section>
  </section>
</template>

<style scoped lang="less">
.home-nav{
  position:static ;
}
.notice{
    background: #D8D8D8 ;
    padding-top: 0px;
    color: #3D3D3D ;
}
.marquee{
  display: flex;
  align-items: center;
  height: 100%;
}
.content-first{
  width: 100%;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  >div{
    width: 30%; 
    min-height: 300px;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 13px;
    padding: 10px;
    margin-right: 10px;
  }
  .yellow{
    background-color:#FFE9CA;
  }
  .red{
    background-color:#F9DDF6;
  }
  .blue{
    background-color:#DBECFE;
  }
  .title{
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #000;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
  }
  .text{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    margin-top: 10px;
  }
  .title .right {
    width: 85%;
  }
  .title .left {
    width: 15%;
    text-align: right;
  }
}
.ensures{
  margin-top: 30px;
  margin-bottom: 30px;
  .title{
      font-size: 20px;
      color: #081035;
      font-family: PingFang SC, PingFang SC;
      font-weight: 700;
      text-align: center;
  }
  .content{
    width: 100%;
    background: url(@/assets/images/EA/ensures@2x.png) no-repeat;
    background-size: 100% 100%;
    height: 500px;
  }
}
.beauty{
  display: flex;
  margin-bottom: 20px;
  .foot-hleps{
    display: none;
  }
  .left{
    width: 50%;
    height: 400px;
    color: #000;
    padding-top: 70px;
    h4{
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 20px;
      color: #000000;
      margin-top: 10px;
    }
    .text{
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
    }
    .imgs{
      display: flex;
      margin-top: 10px;
      margin-bottom: 40px;
    }
    .trde{
      margin-top: 30px;
      width: 80px;
      height: 30px;
      background: #000;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 13px;
    }
    .hleps{
      margin-top: 20px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 40px;
    }
  }
  .right{
    width: 50%;
    height: 380px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .chilren{
      width: 350px;
      height: 300px;
      background: #F9DDF6;
      position: absolute;
      bottom: 0px;
      .img{
        height: 350px;
        position: absolute;
        top: -50px;
        width: 350px;
      }
    }
  }
  .foot-hleps{
    display: none;
  }
}
.chosen{
  margin-top: 40px;
  .title{
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #081035;
    font-family: PingFang SC, PingFang SC;
    font-weight: 700;
    width: 100%;
    padding: 0 20px;
  }
  .content{
    width: 100%;
    height: 300px;
    padding: 10px 0;
    font-size: 12px;
    .first{
      margin-top: 20px;
      width: 100%;
      height: 50px;
      display: flex;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .one{
        width: 16%;
        background-color: #F6F6F6 ;
        height: 100%;
        border-radius: 7px;
        margin-right: 4%;
      }
      .two{
        width: 36%;
        background-color: #1C2125 ;
        height: 100%;
        border-radius: 7px;
        color: #fff;
        margin-right: 4%;
      }
      .three{
        width: 24%;
        background-color: #fff ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
        margin-right: 4%;
      }
      .forth{
        width: 12%;
        background-color: #F6F6F6 ;
        height: 100%;
        border-radius: 7px;
      }
    }
    .second{
      margin-top: 20px;
      width: 100%;
      height: 50px;
      display: flex;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .one{
        width: 31%;
        background-color: #fff ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
        margin-right: 4%;
      }
      .two{
        width: 31%;
        background-color: #fff ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
        margin-right: 4%;
      }
      .three{
        width: 31%;
        background-color: #fff ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
      }
    }
    .third{
      margin-top: 20px;
      width: 100%;
      height: 50px;
      display: flex;
      >div{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .one{
        width: 4%;
        background-color: #F6F6F6 ;
        height: 100%;
        border-radius: 7px;
        margin-right: 4%;
      }
      .two{
        width: 36%;
        background-color: #fff ;
        height: 100%;
        border-radius: 7px;
        color: #1C2125;
        border: 1px solid #BFBFBF;
        margin-right: 4%;
      }
      .three{
        width: 36%;
        background-color: #1C2125 ;
        height: 100%;
        border-radius: 7px;
        color: #fff;
        margin-right: 4%;
      }
      .forth{
        width: 16%;
        background-color: #F6F6F6 ;
        height: 100%;
        border-radius: 7px;
      }
    }
  }
}
.quotes{ 
  margin-bottom: 100px;
    .table-title{
      font-size: 20px;
      color: #081035;
      font-family: PingFang SC, PingFang SC;
      font-weight: 700;
      text-align: center;
    }
    .container{
       background: url(@/assets/images/EA/map@2x.png) no-repeat;
       background-size: 100% 100%;
    }
}
.promblem{
   position: relative;
   background: #F8F8F8;
   border-radius: 20px 20px 20px 20px;
   margin-bottom: 30px;
   height: 250px;
   padding-top: 20px;
  .table-title{
    font-size: 20px;
    color: #081035;
    font-family: PingFang SC, PingFang SC;
    font-weight: 700;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .left{
    position: absolute;
    left: 40px;
    top: -30px;
  } 
  .right{
    position: absolute;
    right: 30px;
    top: -48px;
  }
  .check{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    >div{
      width: 80px;
      height: 30px;
      border-radius: 20px;
      color: #fff;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media ( min-width :1024px) {
    .lmgrren{
      display: none;
    }
    .lggrren{
      display: block;
    }
  }
  .lggrren{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    >div{
      width: 20%;
      margin: auto;
      height: 50px;
      color: #111;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
    }
    .one{
      background: #F9DDF6;
    }
    .two{
      background: #DBECFE;
    }
    .three{
      background: #FFE9CA;
    }
    .four{
      background: #CAE594 ;
    }
  }
  @media ( max-width :1024px) {
    .check{
      display: none;
    }
    .lmgrren{
      display: block;
      >div{
        color: #111;
        width: 100%;
        display: flex;
        flex: row;
        >div{
          width: 35%;
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          margin-bottom: 20px;
          border-radius: 7px;
        }
        .one{
          background: #F9DDF6;
        }
        .two{
          background: #DBECFE;
        }
        .three{
          background: #FFE9CA;
        }
        .four{
          background: #CAE594 ;
        }
      }
    }
    .lggrren{
      display: none;
    }
  }
}
@media ( max-width :1024px) {
  .ensures .content{
    height: 200px;
  }
  .content-first{
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .content-first>div{
    width: 90%;
    min-height: 300px;
    margin: auto;
    margin-bottom: 10px;
    padding: 10px;
  }
  .chosen{
    .content{
      padding: 10px 20px;
    }
  }
  .beauty{
    display: flex;
    flex-direction: column;
    .right{
      width: 100%;
      justify-content: flex-start;
    }
    .left .hleps{
        display: none;
      } 
    .left{
      width: 100%;   
    }
    .foot-hleps{
      display: block;
      margin-top: 20px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 40px;
      color: #000;
    }
  }
}
</style>

<script type="text/javascript">
import TopBanners from "./Components/TopBanners-4.2.vue";
import HomeQuotes from './Components/HomeQuotes.vue';
import PartnerLinks from "./Components/_PartnerLinks-4.2.vue";
import "@/assets/svg/icon-login.svg";
import "@/assets/svg/icon-wallet.svg";
import "@/assets/svg/icon-assets.svg";
import "@/assets/svg/icon-invite.svg";
import "@/assets/svg/icon-notice.svg";

import "@/assets/svg/v4.2/v4.2-login.svg";
import "@/assets/svg/v4.2/v4.2-wallet.svg";
import "@/assets/svg/v4.2/v4.2-assets.svg";
import "@/assets/svg/v4.2/v4.2-notice.svg";
import HorizontalNotices from './Components/_ScrollNotices_Horizontal.vue';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import HomeNotices from './Components/HomeNotices.vue';
import BottomNav from '../Components/BottomNav-4.2.vue';
import { SymbolInfo } from "utilities/SymbolInfo.js";
import { getSymbolManagerAsync, getAppContext } from "utilities/helper";
let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
  components: {
    TopBanners,
    HomeQuotes,
    PartnerLinks,
    HorizontalNotices,
    InlineSvgIcons,
    HomeNotices,
    BottomNav
  },
    data() {
        return {
            // All symbols.
            symbols: null,
            // App context
            context: null,
            active_tab: '/',
        };
    },

    computed: {
        // return a list of prompted symbols
        promptedSymbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            return arr;
        }
    },

    created() {
        g_destoryed = false;
        this.context = getAppContext();
        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },

    beforeDestroy() {
        g_destoryed = true;

        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;

                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);

                    sidMap[sym.metadata.id] = sym;
                });

                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;

                Vue.nextTick(() => {
                    // start to subscribe realtime quote data
                    self.syncQuotes();
                });
            }
        },
        goToTradePage() {
            this.$router.push('/futures')
        },
        syncQuotes: function () {
            const self = this;
            // clear existing timer if any
            clearTimeout(g_quoteTimerId);

            const sids = [];
            $(this.promptedSymbols).each((index, sym) => {
                sids.push(sym.metadata.id);
            });

            try {
                const temp = this.$refs.quotes.getVisibleSids();
                if (Array.isArray(temp)) {
                    for (let i = 0; i < temp.length; i++) {
                        sids.push(temp[i]);
                    }
                }
            } catch (err) {
                console.error(err);
            }

            if (sids.length > 0) {
                // Read quotes from server
                self.$http
                    .get('/api/v1/quotation/latest?symbols=' + sids.join(','))
                    .then((json) => {
                        const quotes = json.data;
                        if (quotes.length > 0) {
                            for (let i = 0; i < quotes.length; i++) {
                                const quote = quotes[i];
                                const sym = g_symbolMap[quote.id];
                                if (sym) {
                                    sym.update(quote);
                                }
                            }
                        }
                    })
                    .then(() => {
                        if (g_destoryed === false) {
                            let delay = 4000 + Math.round(Math.random() * 3000);
                            g_quoteTimerId = setTimeout(() => {
                                self.syncQuotes();
                            }, delay);
                        }
                    });
            }
        }
    }
};
</script>
