<template>
    <section class="page quotes-page">
        <section class="pro_layout">
            <loading-indicator v-if="!symbols" />
            <div class="market-quotations-container" v-else>
                <div class="coin-container">
                    <inline-svg-icons :symbols="symbols" />
                    <div class="page-top" :class="{ 'page-top-hk': sysconfig.is_hk }">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="mobile-title">
                                        <h1>{{ $t('home.footer_quotes') }}</h1>
                                        <div>{{ $t('futures.future_tips', { platform: sysconfig.name }) }}</div>
                                    </div>
                                    <div class="col  d-flex justify-content-end">
                                        <!-- search -->
                                        <div class="input-group input-group-lg">
                                            <div class="input-group-text">
                                                <svg viewBox="0 0 1024 1024" width="32" height="32">
                                                    <path d="M945.066667 898.133333l-189.866667-189.866666c55.466667-64 87.466667-149.333333 87.466667-241.066667 0-204.8-168.533333-373.333333-373.333334-373.333333S96 264.533333 96 469.333333 264.533333 842.666667 469.333333 842.666667c91.733333 0 174.933333-34.133333 241.066667-87.466667l189.866667 189.866667c6.4 6.4 14.933333 8.533333 23.466666 8.533333s17.066667-2.133333 23.466667-8.533333c8.533333-12.8 8.533333-34.133333-2.133333-46.933334zM469.333333 778.666667C298.666667 778.666667 160 640 160 469.333333S298.666667 160 469.333333 160 778.666667 298.666667 778.666667 469.333333 640 778.666667 469.333333 778.666667z" fill="#666666" p-id="2868"></path>
                                                </svg>
                                            </div>
                                            <input type="text" class="form-control" v-model="search_term" placeholder="BTC, ETH..." />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-for="(sym, pos) in prompted_symbols" :key="pos" class="col" :class="{ 'd-none d-sm-block': pos > 1 }">
                                        <prompted-symbol_v4 v-if="$version === '4.1' || $version === '4.3'" :symbol="sym" />
                                        <prompted-symbol v-else :symbol="sym" />
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div  class="container  main-content">
                        <div class="table container">
                            <div class="virtual-asset-box">
                                <img src="@/assets/images/EA/rank.png" class="left" />
                                <div class="virtual-asset-text">虚拟资产排名</div> 
                            </div>
                        <!-- symbol types -->
                            <div class="row">
                                <div class="col page-top-nav">
                                    <ul>
                                        <li :class="{ active: selected_type === 2 }">
                                            <a href="javascript:;" @click="selected_type = 2">{{ $t('general.futures') }}</a>
                                        </li>
                                        <li :class="{ active: selected_type === 3 }">
                                            <a href="javascript:;" @click="selected_type = 3">{{ $t('general.exchange') }}</a>
                                        </li>
                                        <li v-if="sysconfig.bOptionEnabled === true" :class="{ active: selected_type === 1 }">
                                            <a href="javascript:;" @click="selected_type = 1">{{ $t('general.boption') }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        <!-- symbols -->
                            <div class="row">
                                <div class="col">
                                    <symbol-list v-if="selected_type === 2" :title="$t('general.futures')" :symbols="symbols" :typeFilter="2" :searchTerm="search_term" @symbol-selected="gotoTradePage" />
                                    <symbol-list v-if="selected_type === 3" :title="$t('general.exchange')" :symbols="symbols" :typeFilter="3" :searchTerm="search_term" @symbol-selected="gotoTradePage" />
                                    <symbol-list v-if="selected_type === 1" :title="$t('general.boption')" :symbols="symbols" :typeFilter="1" :searchTerm="search_term" @symbol-selected="gotoTradePage" />
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<style scoped>
.market-quotations-container{
    display:flex;
    justify-content: space-between;
}
.mr-t-22{
    margin-bottom: 22px;
}
.notices-event-box{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14px;
}
.event-box{
    width: 70px;
    height: 24px;
    border-radius: 4px;
    opacity: 1;
    background: #F9E4F6;
    padding: 5px 5px;
    font-size: 10px;
    text-align: center;
}
.ps-3{
    color:#AEB3BB;
    font-size: 12px;
    line-height: 24px;
}
.notice_big_title{
    font-weight: 600;
    font-size: 32px;
    width:306px;
    margin: 2rem 0;
}
.notice_title_box{
    display: flex;
    justify-content: space-between;
    margin-bottom:20px;
}
.page-title,.see_more_box{
    width: 50%;
}
.page-title{
    font-size: 18px;
    font-weight: 700;
}
.see_more_box{
    text-align: right;
    padding-right: 10px;
    padding-top: 20px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #79818F;
}
.coin-container{
    width: 100%;
}
.coin-container .container{
    background: #fff;
    padding-top: 15px;
}
.quotes-page .page-top {
    background: #fff;
}

.quotes-page .page-top-nav {
    margin-top: 0;
}

.input-group>.form-control {
    padding-left: 40px; 
    border-radius: 12px !important;
}

.input-group>.input-group-text {
    border-right: 0;
    background: #fff;
    padding-left: 0.25rem;
    padding-right: 0;
    position: absolute;
    left: 0px;
    top: 2px;
    z-index: 11;
    border: none;
}

.input-group-text>svg {
    height: 1.5rem;
}

.form-control:focus {
    border-color: var(--primary-5);
    box-shadow: none;
}
.main-content{
    display: flex;
}
.virtual-asset-box{
    display: flex;
    margin-top: 3rem;
}
.virtual-asset-text{
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-left: 12px;
}
.page-top-nav ul {
    margin: 2rem 0 0 0;
    display: flex;
}
.page-top-nav ul li{
    min-width: 60px;
}
@media (min-width: 1024px) {
    .mobile-title{
        display:none;
    }
    .input-group {
        margin: 2rem 0;
        max-width: 500px;
    }

    .input-group>.form-control {
        border-radius: 12px !important;
    }

    .input-group>.input-group-text {
        border-radius: 1rem 0 0 1rem;
    }
}

@media (max-width: 1024px) {
    .table{
        width: 100%;
    }
    .mobile-title h1{
        text-align: center;
        font-weight: 500;
        font-size: 3rem;
        margin-bottom:0.86rem;
    }
    .coin-container{
        width:100%;
    }
    .page-top {
        background-size: 110% 15rem;
    }
    .input-group {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

.page-top-hk {
    background-image: url(@/assets/images/v4.1/quotes-top-hk.jpg);
}
</style>

<script>
import SymbolList from './Components/SymbolList.vue';
import { SymbolInfo } from 'utilities/SymbolInfo.js';
import { getSymbolManagerAsync } from 'utilities/helper';
import InlineSvgIcons from '../Components/_InlineSvgIcons.vue';
import PromptedSymbol from './Components/_PromptedSymbol.vue';
import PromptedSymbol_v4 from './Components/_PromptedSymbol-4.1.vue';

let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
    components: { SymbolList, InlineSvgIcons, PromptedSymbol, PromptedSymbol_v4 },
    data() {
        return {
            // All symbols.
            symbols: null,
            search_term: null,
            // Display future symbols by default.
            selected_type: 2
        };
    },

    computed: {
        prompted_symbols: function () {
            let arr = this.symbols.filter(function (s) {
                return s.metadata.prompted && !s.metadata.testOnly;
            });
            // Only take up to 4 symbols
            if (arr.length > 4) {
                arr = arr.slice(0, 4);
            }
            return arr;
        },
    },

    created() {
        g_destoryed = false;
        // clear all existing symbols
        g_symbolMap = {};
        this.initPageAsync();
    },
    beforeDestroy() {
        g_destoryed = true;
        // BUGFIX:
        // Must unbind the resize event.
        // $(window).unbind('resize');
        // clear all timers
        clearTimeout(g_quoteTimerId);
    },

    methods: {
        initPageAsync: async function () {
            // Read symbols.
            const mgr = await getSymbolManagerAsync();
            const sidMap = {};
            if (mgr) {
                const self = this;
                let arr = [];
                $(mgr.getAllSymbols()).each((index, item) => {
                    let sym = new SymbolInfo(item);
                    arr.push(sym);
                    sidMap[sym.metadata.id] = sym;
                });
                g_symbolMap = Object.freeze(sidMap);
                self.symbols = arr;
                // start to subscribe realtime quote data
                self.syncQuotes();
            }
        },
        gotoTradePage: function (sym) {
            let route = sym.getRoutePath();
            this.$router.push({ path: route });
        },
        syncQuotes: function () {
            const self = this;
            // clear existing timer if any
            clearTimeout(g_quoteTimerId);
            const do_sync = function () {
                const sids = [];
                for (let sid in g_symbolMap) {
                    sids.push(sid);
                }
                if (sids.length > 0) {
                    // Read quotes from server
                    self.$http
                       .post('/api/v1/quotation/latest', {symbols: sids.join(',')})
                        .then((json) => {
                            const quotes = json.data;
                            if (quotes.length > 0) {
                                for (let i = 0; i < quotes.length; i++) {
                                    const quote = quotes[i];
                                    const sym = g_symbolMap[quote.id];
                                    if (sym) {
                                        sym.update(quote);
                                    }
                                }
                            }
                        })
                        .then(() => {
                            if (g_destoryed === false) {
                                // always restart timer
                                let delay = 4000 + Math.round(Math.random() * 3000);
                                g_quoteTimerId = setTimeout(do_sync, delay);
                            }
                        });
                }
            };
            do_sync();
        }
    }
};
</script>