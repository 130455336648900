
/*
 * ======================================================================================
 * Import pages
 */
import HomePage from '@/pages/Home/Index-4.2.vue';
import QuotesPage from '@/pages/Home/Quotes.vue'
import KnowledgesPage from '@/pages/Home/Knowledges.vue';
import NoticePage from '@/pages/Notice/Item.vue';
import NoticeListPage from '@/pages/Notice/Notices.vue';
import PostPage from '@/pages/Home/Post.vue';

import BinaryOptionPage from '@/pages/BinaryOption/Index.vue';
import BinaryOptionOpenOrdersPage from '@/pages/BinaryOption/OpenOrders.vue';
import BinaryOptionOrdersPage from '@/pages/BinaryOption/Orders.vue';

import FuturesPage from '@/pages/Futures/Index.vue';
import FuturesOpenOrdersPage from '@/pages/Futures/OpenOrders.vue';
import FuturesOrdersPage from '@/pages/Futures/Orders.vue';

import ExchangePage from '@/pages/Exchange/Index.vue';
import ExchangeOrdersPage from '@/pages/Exchange/Orders.vue';
import StartupListPage from '@/pages/Startup/Index.vue';
import StartupItemPage from '@/pages/Startup/Item.vue';
import StartupOrdersPage from '@/pages/Startup/Orders.vue';

import UserLoginPage from '@/pages/User/Login-4.2.vue';
import UserCreatePage from '@/pages/User/Create.vue';
import UserSignoutPage from '@/pages/User/Signout.vue';
import UserSettingsPage from '@/pages/User/Index-4.1.vue';
import UserBankPage from '@/pages/User/BankAccount.vue';
import UserBankAccountsPage from '@/pages/User/BankAccounts.vue';
import UserIdentityVerificationPage from '@/pages/User/IdentityVerification.vue';
import UserResetPasswordPage from '@/pages/User/ResetPassword.vue';
// import UserChangePasswordPage from '@/pages/User/ChangePassword.vue';
import UserChangeWithdrawPasswordPage from '@/pages/User/ChangeWithdrawPassword.vue';
import UserBalancePage from '@/pages/User/Balance-4.1.vue';
import UserHelpsPage from '@/pages/User/Help/Index.vue';
import UserHelpItemPage from '@/pages/User/Help/Item.vue';
import UserReferralsPage from '@/pages/User/Referrals.vue';
import EditPhonePage from '@/pages/User/EditPhone.vue';
import EditEmailPage from '@/pages/User/EditEmail.vue';
import EditGAPage from '@/pages/User/EditGA.vue';

import DepositPage from '@/pages/Finance/Deposit.vue';
// import DepositCompletedPage from '@/pages/Finance/DepositCompleted.vue';
import WithdrawPage from '@/pages/Finance/Withdraw.vue';
import FinanceOrdersPage from '@/pages/Finance/Orders.vue';
import FinanceHistoryPage from '@/pages/Finance/History.vue';

// Saving
import SavingHomePage from '@/pages/Saving/Index.vue';
import SavingHistoryPage from '@/pages/Saving/History.vue';
import SavingAccountPage from '@/pages/Saving/Account.vue';
//Lucky wheel
import luckyDraw from '@/pages/Lucky/Lucky.vue';

import CollegePage from '@/pages/College/Index.vue';
import PostCategoryPage from '@/pages/College/Category.vue';
import HelpPage from '@/pages/Help/Item.vue';
import NotFound from '@/pages/404.vue';
import NotificationPage from '@/pages/Notification/Notification.vue';

/**
 * Return an array of app routes.
 * @returns An array of app routes.
 */
export const getRoutes = function () {
    return [
        { path: '/', component: HomePage, meta: { tradeLayout: true } },
        { path: '/quotes', component: QuotesPage },
        { path: '/kn', component: KnowledgesPage },
        { path: '/notice/:id', name: 'notice', component: NoticePage, props: true },
        { path: '/notices', component: NoticeListPage },
        { path: '/post/:id', name: 'post', component: PostPage, props: true },
        { path: '/notification', component: NotificationPage },
        
        { path: '/luckyDraw', component: luckyDraw },

        { path: '/boption/openorders', component: BinaryOptionOpenOrdersPage },
        { path: '/boption/orders', component: BinaryOptionOrdersPage },
        { path: '/boption/:id?', component: BinaryOptionPage, props: true, meta: { tradeLayout: true } },

        { path: '/futures/openorders', component: FuturesOpenOrdersPage },
        { path: '/futures/orders', component: FuturesOrdersPage },
        { path: '/futures/:id?', component: FuturesPage, props: true, meta: { tradeLayout: true } },

        { path: '/exchange/orders', component: ExchangeOrdersPage },
        { path: '/exchange/:id?', component: ExchangePage, props: true, meta: { tradeLayout: true } },
        { path: '/startup', component: StartupListPage },
        { path: '/startup/orders', component: StartupOrdersPage },
        { path: '/startup/:id?', component: StartupItemPage, props: true },

        { path: '/user', component: UserSettingsPage },
        { path: '/user/login', component: UserLoginPage, meta: { slimFooter: true, darkLayout: true } },
        { path: '/user/create/:id?', component: UserCreatePage, props: true, meta: { slimFooter: true, darkLayout: true } },
        { path: '/user/signout', component: UserSignoutPage },
        { path: '/user/balance', component: UserBalancePage },
        { path: '/user/bankaccount/:id?', component: UserBankPage , props: true },
        { path: '/user/bankaccounts', component: UserBankAccountsPage },
        { path: '/user/verifyid', component: UserIdentityVerificationPage },
        { path: '/user/reset', component: UserResetPasswordPage, meta: { slimFooter: true, darkLayout: true } },
        // It was asked to always use sms verification when changing password.
        // { path: '/user/changepwd', component: UserChangePasswordPage },
        { path: '/user/changewithdrawpwd', component: UserChangeWithdrawPasswordPage, props: true },
        { path: '/user/referrals', component: UserReferralsPage },
        { path: '/user/email', component: EditEmailPage },
        { path: '/user/phone', component: EditPhonePage },
        { path: '/user/ga', component: EditGAPage },


        { path: '/finance/withdraw/:id?', component: WithdrawPage, props: true },
        { path: '/finance/deposit', component: DepositPage },
        // { path: '/finance/depositdone', component: DepositCompletedPage },
        { path: '/finance/orders', component: FinanceOrdersPage },
        { path: '/finance/history', component: FinanceHistoryPage },

        { path: '/user/helps', component: UserHelpsPage },
        { path: '/user/help/:id', name: 'help', component: UserHelpItemPage, props: true },

        { path: '/saving', component: SavingHomePage, meta: { title: 'home.title' } },
        { path: '/saving/history', component: SavingHistoryPage, meta: { title: 'home.title' } },
        { path: '/saving/account', component: SavingAccountPage, meta: { title: 'home.title' } },

        { path: '/college/:id', component: PostCategoryPage, props: true },
        { path: '/college', component: CollegePage },

        { path: '/help/:id', component: HelpPage, props: true },
        { path: '/notfound', component: NotFound },
        { path: '*', component: NotFound },
    ];
}